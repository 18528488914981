// color variants
@import "themes-vars.module.scss";

// third-party
@import " ~react-perfect-scrollbar/dist/css/styles.css";
@import " ~slick-carousel/slick/slick.css";
@import " ~slick-carousel/slick/slick-theme.css";

@import " ~react-18-image-lightbox/style.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

::placeholder {
  font-style: italic;
  font-family: 'Roboto';
}

.preBuildDashBoard-slider {
  overflow: hidden;
  .slider {
    .slide {
      opacity: 0.5;
      display: flex;
      justify-content: center;
      .custom-slider {
        gap: 40px;
      }
      &.selected {
        opacity: 1;
        .custom-slider {
          display: flex;
          flex-direction: column;
        }
      }
      &:not(.selected) {
        transform: scale(0.7);
        transform-origin: top;
      }
    }
  }
}

.project-info {
  .project-slider {
    .slick-list {
      padding: 0 !important;
    }
  }
  .slick-slide {
    opacity: 0.05;
  }
  .slick-active {
    opacity: 0.2;
  }
  .slick-current {
    opacity: 1 !important;
    .MuiTypography-root {
      color: $primaryMain;
    }
  }
}

.ril__inner {
  direction: ltr;
}

.ReactModal__Overlay {
  z-index: 99999 !important;
}

.hover-select-grid:hover {
  border-color: $primaryMain;
  box-shadow: 0 0 5px $primaryMain;
  cursor: pointer;
}

.MuiInputBase-readOnly::before,
.MuiInputBase-readOnly .MuiSvgIcon-root {
  display: none;
}

.MuiInputBase-input {
  font-weight: 400;
}

.MuiInputLabel-root {
  line-height: 1;
}

.MuiInputLabel-shrink {
  font-size: 1.1rem;
  font-weight: 600;
}

.report-filters-style-class {
  display: flex;
  flex-direction: column;
}

.report-style-class {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100% !important;
  iframe {
    border: none;
    height: 100% !important;
  }
}

.d-none {
  display: none;
}

//.css-veys2a-MuiGrid-root > .MuiGrid-item {
//  padding-left: 0px;
//  padding-top: 0px;
//}

.MuiDataGrid-virtualScroller,
.MuiDataGrid-overlayWrapperInner {
  min-height: 25px;
}

main {
  .MuiContainer-root {
    height: 100%;
  }
}

.MuiButton-root {
  text-transform: uppercase;
  box-shadow: none;
}

ul span {
  font-size: 14px;
}

.MuiList-subheader span,
ul .MuiTypography-body1,
ul .MuiTypography-h6 {
  font-size: 14px;
}

.MuiList-subheader span {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.MuiList-subheader .MuiListItemIcon-root svg,
.scrollbar-container .MuiListItemIcon-root svg {
  color: #0078cf !important;
}

.MuiTypography-body2,
.Mui-selected {
  font-size: 16px;
}

.react-joyride__tooltip h1 {
  color: #687485;
}

.MuiTabs-scroller {
  .MuiTab-root {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .MuiTabs-indicator {
    height: 4px;
  }
}

pre {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

// prevent overflow mobile devices
@media (max-width: 768px) {
  main {
    overflow: hidden; /* Hide overflow on mobile devices */
  }
}


.hideMinutes .MuiDateTimePickerToolbar-timeDigitsContainer button:nth-of-type(2) {
  display: none; /* Hides the second button */
}

.hideMinutes .MuiDateTimePickerToolbar-timeDigitsContainer > span:first-of-type {
  display: none; /* Hides the first span directly under timeDigitsContainer */
}

.Mui-disabled {
  cursor: not-allowed;
}

.nav-info-icon a::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: #0078CF;
  border-radius: 50%;
  margin-right: 10px;
}

a {
  color: #0078CF;
  text-decoration: none;
}
