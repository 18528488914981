// paper & background
$paper: #ffffff;

// primary
$primaryLight: #f8fafc;
$primaryMain: #0078cf;
$primaryDark: #0071c2;
$primary200: #dfeefa;
$primary800: #283593;

// secondary
$secondaryLight: #eef2f6;
$secondaryMain: #0078cf;
$secondaryDark: #0078cf;
$secondary200: #eef2f6;
$secondary800: #076ea7;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #bf3030;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #eebd43;
$warningDark: #ffc107;

// grey
$grey50: #f8fafc;
$grey100: #eef2f6;
$grey200: #e3e8ef;
$grey300: #cdd5df;
$grey400: #6A6D8D;
$grey500: #687485;
$grey600: #4b5565;
$grey700: #364152;
$grey900: #121926;

// PowerBI specific colors
$powerbi-blue1: #27aae1;
$powerbi-blue2: #78e1e8;
$powerbi-blue3: #0980bc;
$powerbi-blue4: #003975;
$powerbi-blue5: #238ab7;
$powerbi-blue6: #6fc2df;

// disabled
$disabledMain: #b7c0cb;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a2027; // level 3
$darkPaper: #262836; // level 4
$darkPaperAlt: #252836; // Alternative dark paper

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #0078cf;
$darkPrimaryMain: #0078cf;
$darkPrimaryDark: #0078cf;
$darkPrimary200: #0078cf;
$darkPrimary800: #0078cf;

// secondary dark
$darkSecondaryLight: #1f1d2b; // Fixed: was using darkGrey200
$darkSecondaryMain: #0078cf;
$darkSecondaryDark: #0078cf;
$darkSecondary200: #0078cf;
$darkSecondary800: #0078cf;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #0078cf;
$darkTextSecondary: #0078cf;
$darkTextMuted: #BDCAD6;
$darkTextBright: #FDFEFE;

// success Colors
$darkSuccessMain: #53b095;
$darkSuccessBackground: #53b095;
$darkSuccessStroke: #28534a;
$darkSuccessText: #b9e9e2;

// warning Colors
$darkWarningMain: #eebd43;
$darkWarningBackground: #8d6e10;
$darkWarningStroke: #b48e1e;
$darkWarningText: #fff8e1;

// error colors
$darkErrorMain: #bf3030;
$darkErrorBackground: #850001;
$darkErrorStroke: #b10e0f;
$darkErrorText: #fcf5f5;

// UI Components
$darkGrey100: #262f3d;
$darkGrey200: #1f1d2b;
$darkGrey300: #687485;
$darkGrey400: #424458;
$darkGrey500: #a9b2bb;
$darkGrey600: #eef2f6;
$darkGrey700: #f8fafc;
$darkGrey800: #fdfefe;

// PowerBI specific UI colors
$darkGridline: #e3e8ef;
$darkBorder: #6A6D8D;

// disabled
$darkDisabledMain: #a9b2bb;

// ==============================|| JAVASCRIPT ||============================== //

// Add new PowerBI specific variables
$powerbi-background: $paper;
$powerbi-background-alt: $grey50;
$powerbi-background-darker: $grey900;

$powerbi-text: $grey700;
$powerbi-text-secondary: $grey500;
$powerbi-text-muted: $grey300;

// Dark mode PowerBI variables
$powerbi-dark-background: $darkBackground;
$powerbi-dark-background-alt: $darkPaper;
$powerbi-dark-background-darker: $darkLevel2;

$powerbi-dark-text: $darkTextPrimary;
$powerbi-dark-text-secondary: $darkTextSecondary;
$powerbi-dark-text-muted: $darkTextMuted;

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey400: $grey400;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;

  // PowerBI colors
  powerbiBlue1: $powerbi-blue1;
  powerbiBlue2: $powerbi-blue2;
  powerbiBlue3: $powerbi-blue3;
  powerbiBlue4: $powerbi-blue4;
  powerbiBlue5: $powerbi-blue5;
  powerbiBlue6: $powerbi-blue6;

  // disabled
  disabledMain: $disabledMain;

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;
  darkPaperAlt: $darkPaperAlt;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;
  darkTextMuted: $darkTextMuted;
  darkTextBright: $darkTextBright;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;

  // UI Components
  darkGrey100: $darkGrey100;
  darkGrey200: $darkGrey200;
  darkGrey300: $darkGrey300;
  darkGrey400: $darkGrey400;
  darkGrey500: $darkGrey500;
  darkGrey600: $darkGrey600;
  darkGrey700: $darkGrey700;
  darkGrey800: $darkGrey800;

  // PowerBI specific UI
  darkGridline: $darkGridline;
  darkBorder: $darkBorder;

  // success dark
  darkSuccessMain: $darkSuccessMain;
  darkSuccessBackground: $darkSuccessBackground;
  darkSuccessStroke: $darkSuccessStroke;
  darkSuccessText: $darkSuccessText;

  // warning dark
  darkWarningMain: $darkWarningMain;
  darkWarningBackground: $darkWarningBackground;
  darkWarningStroke: $darkWarningStroke;
  darkWarningText: $darkWarningText;

  // error dark
  darkErrorMain: $darkErrorMain;
  darkErrorBackground: $darkErrorBackground;
  darkErrorStroke: $darkErrorStroke;
  darkErrorText: $darkErrorText;

  // disabled
  darkDisabledMain: $darkDisabledMain;

  // Add new PowerBI exports
  powerbiBackground: $powerbi-background;
  powerbiBackgroundAlt: $powerbi-background-alt;
  powerbiBackgroundDarker: $powerbi-background-darker;
  
  powerbiText: $powerbi-text;
  powerbiTextSecondary: $powerbi-text-secondary;
  powerbiTextMuted: $powerbi-text-muted;
  
  powerbiDarkBackground: $powerbi-dark-background;
  powerbiDarkBackgroundAlt: $powerbi-dark-background-alt;
  powerbiDarkBackgroundDarker: $powerbi-dark-background-darker;
  
  powerbiDarkText: $powerbi-dark-text;
  powerbiDarkTextSecondary: $powerbi-dark-text-secondary;
  powerbiDarkTextMuted: $powerbi-dark-text-muted;
}
